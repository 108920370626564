import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TextareaAutosize,
  FormControl,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TopBar from '../common/TopBar';
import FooterNavbar from '../common/FooterNavbar';
import { sendEmail } from './utils/sendEmail';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

// Componentes estilizados
const buttonStyle = {
  backgroundColor: '#A52422',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#FF5722',
  },
};

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(1.5, 1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid #222`,
  color: theme.palette.text.primary,
  backgroundColor: '#1E1E1E',
  '&:focus': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
  },
}));

const Settings = () => {
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const fullScreen = false; // Você pode ajustar conforme necessário

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [openAuthDialog, setOpenAuthDialog] = useState(false);
  const [authDialogMessage, setAuthDialogMessage] = useState('Você precisa estar autenticado para usar essa funcionalidade.');

  const handleVerifyEmail = () => {
    navigate('/verify-email');
  };

  useEffect(() => {
    // Escuta as mudanças no estado de autenticação
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
        setIsEmailVerified(user.emailVerified);
      } else {
        setIsAuthenticated(false);
      }
    });

    // Limpa o listener quando o componente for desmontado
    return () => unsubscribe();
  }, []);

  const handleOpenContactModal = () => {
    if (isAuthenticated) {
      if (!isEmailVerified) {
        setAuthDialogMessage('Você precisa verificar o seu e-mail para usar essa funcionalidade.');
        setOpenAuthDialog(true);
      } else {
        setOpenContactModal(true);
      }
    } else {
      setAuthDialogMessage('Você precisa estar autenticado para usar essa funcionalidade.');
      setOpenAuthDialog(true);
    }
  };
  
  const handleCloseContactModal = () => setOpenContactModal(false);
  const handleOpenLogoutModal = () => setOpenLogoutModal(true);
  const handleCloseLogoutModal = () => setOpenLogoutModal(false);

  const handleSendContact = async () => {
    if (message.length >= 50) {
      try {
        await sendEmail({
          subject: `${description}`,
          message,
          to: 'worshipcifrasbr@gmail.com',
          cc: 'gustavo.honorio@yahoo.com.br',
        });
        setSnackbarMessage('Mensagem enviada com sucesso!');
        setSnackbarSeverity('success');
      } catch (error) {
        setSnackbarMessage('Erro ao enviar a mensagem. Tente novamente mais tarde.');
        setSnackbarSeverity('error');
      }
      setOpenContactModal(false);
      setSnackbarOpen(true);
    } else {
      alert('A mensagem precisa ter pelo menos 50 caracteres.');
    }
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/signin');
    });
  };

  const handleNavigateToPrivacyPolicy = () => {
    navigate('/privacy-policy');
  };

  const handleNavigateToTermsConditions = () => {
    navigate('/terms-conditions');
  };

  // Funções para o diálogo de autenticação
  const handleAuthDialogClose = () => {
    setOpenAuthDialog(false);
  };

  const handleNavigateToLogin = () => {
    navigate('/signin');
    setOpenAuthDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <TopBar title="Configurações" />

      {/* Menu da tela */}
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Configurações</Typography>

        {/* "Fale Conosco" Menu */}
        <Box onClick={handleOpenContactModal} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1">Fale conosco</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />

        {/* "Termos e Condições" Menu */}
        <Box onClick={handleNavigateToTermsConditions} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1">Termos e condições</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />
        
        {/* "Políticas de Privacidade" Menu */}
        <Box onClick={handleNavigateToPrivacyPolicy} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1">Políticas de privacidade</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />

        {/* "Sair" Menu */}
        <Box onClick={handleOpenLogoutModal} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1" color="error">Sair</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />
      </Box>

      {/* Dialog Fale Conosco */}
      <Dialog
        open={openContactModal}
        onClose={handleCloseContactModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#fff',
            borderRadius: '10px',
            maxHeight: 'calc(100% - 32px)',
          },
        }}
      >
        <DialogTitle>Fale Conosco</DialogTitle>
        <DialogContent dividers style={{ overflowY: 'auto' }}>
          <TextField
            placeholder="Descrição resumida"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />          
          <FormControl fullWidth variant="outlined">              
            <StyledTextarea
              id="short-bio"
              aria-label="Queremos te ouvir"
              minRows={5}
              maxRows={10}
              placeholder="Escreva detalhadamente sua mensagem aqui.."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button sx={buttonStyle} onClick={handleSendContact}>Enviar</Button>
          <Button onClick={handleCloseContactModal} color="primary">Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de Logout */}
      <Dialog
        open={openLogoutModal}
        onClose={handleCloseLogoutModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#fff',
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle>Deseja sair?</DialogTitle>
        <DialogActions>
          <Button sx={buttonStyle} onClick={handleLogout}>Sim</Button>
          <Button onClick={handleCloseLogoutModal} color="primary">Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para usuários não autenticados ou com e-mail não verificado */}
      <Dialog
        open={openAuthDialog}
        onClose={handleAuthDialogClose}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#fff',
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle>Aviso</DialogTitle>
        <DialogContent>
          <Typography>
            {authDialogMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAuthDialogClose} color="primary">
            Cancelar
          </Button>
          {isAuthenticated && !isEmailVerified ? (
            <Button onClick={handleVerifyEmail} color="primary" variant="contained">
              Verificar e-mail
            </Button>
          ) : (
            <Button onClick={handleNavigateToLogin} color="primary" variant="contained">
              Realizar Login
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar para feedback de sucesso/erro */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <FooterNavbar />
    </Box>
  );
};

export default Settings;
