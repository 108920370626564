// App.js
import React from 'react';
// Importando fontes Roboto
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Importando react-router-dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Importando componentes
import SignIn from './components/account/sign-in/SignIn';
import SignUp from './components/account/sign-up/SignUp';
import EmailVerification from './components/account/utils/EmailVerification';
import Home from './components/home/Home';
import Search from './components/search/Search';
import Recommend from './components/recommend/Recommend';
import Song from './components/song/Song';
import Artist from './components/artist/Artist';
import Settings from './components/settings/Settings';
import AccountDetail from './components/account/details/AccountDetail';
import CreateArtist from './components/artist/CreateArtist';
import CreateSong from './components/song/CreateSong';
import EditSong from './components/song/EditSong';
import PrivacyPolicy from './components/settings/PrivacyPolicy';
import TermsConditions from './components/settings/TermsConditions';
import AuthRedirect from './components/account/utils/AuthRedirect';
// Importando tema
import AppTheme from './assets/shared-theme/AppTheme';
import CssBaseline from '@mui/material/CssBaseline';
// Importando AuthProvider e PrivateRoute
import { AuthProvider } from './components/auth/AuthContext';
import PrivateRoute from './components/auth/PrivateRoute';

const App = () => {
  return (
    <AppTheme>
      <CssBaseline /> {/* Aplica estilos globais e garante a consistência visual */}
      <Router>
        <AuthProvider>
          <Routes>
            {/* Rotas públicas */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/auth-redirect" element={<AuthRedirect />} />

            <Route path="/" element={<Home />} />

            <Route path="/search" element={<Search />} />
            <Route path="/recommend/:tag" element={<Recommend />} />
            
            <Route path="/settings" element={<Settings />} />

            <Route path="/song/:id" element={<Song />} />
            <Route path="/artist/:id" element={<Artist />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />

            {/* Rotas protegidas */}
            <Route
              path="/create-song"
              element={
                <PrivateRoute>
                  <CreateSong />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-song/:id"
              element={
                <PrivateRoute>
                  <EditSong />
                </PrivateRoute>
              }
            />

            <Route
              path="/create/artist"
              element={
                <PrivateRoute>
                  <CreateArtist />
                </PrivateRoute>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AccountDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/verify-email"
              element={
                <PrivateRoute>
                  <EmailVerification />
                </PrivateRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </AppTheme>
  );
};

export default App;
