// Song.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { fetchSongData } from './utils/functions/fetchSongData';
import { formatSong } from './utils/functions/formatSong';
import { transposeChords } from './utils/functions/transposeChords';
import { auth, firestore } from '../../firebase';
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  increment,
  updateDoc,
  collection,
  query,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import { sendEmail } from './utils/functions/sendEmail';
import NavigationBar from '../common/NavigationBar';
import SongInfo from './utils/components/SongInfo';
import TabSelector from './utils/components/TabSelector';
import SongActions from './utils/components/SongActions';
import SongContent from './utils/components/SongContent';
import AuthDialog from '../common/AuthDialog';
import ReportDialog from './utils/components/ReportDialog';
import YouTubePlayer from './utils/components/YoutubePlayer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import CreateIcon from '@mui/icons-material/Create';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';
import { CircularProgress } from '@mui/material';
import ChordDiagram from './utils/components/ChordDiagram';

const Song = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [song, setSong] = useState(null);
  const [songContent, setSongContent] = useState('');
  const [chords, setChords] = useState('');
  const [chordsComponent, setChordsComponent] = useState(null);
  const [lyricsComponent, setLyricsComponent] = useState(null);
  const [details, setDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Cifra');
  const [tone, setTone] = useState('');
  const [originalTone, setOriginalTone] = useState('');

  // Estado para o botão de like
  const [liked, setLiked] = useState(false);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(null);

  // Estado para autenticação
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  // Estado para o dialog de reportar erros
  const [openDialog, setOpenDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [reportMessage, setReportMessage] = useState('');

  // Estado para o player
  const [showPlayer, setShowPlayer] = useState(false);
  const [videoId, setVideoId] = useState(null);

  const isMobile = useMediaQuery('(max-width:600px)');

  // Estado para a seção de acordes
  const [showChords, setShowChords] = useState(false);
  const [chordsList, setChordsList] = useState([]);
  const [focusChord, setFocusChord] = useState(null);
  const chordSectionRef = useRef(null);
  const [capo, setCapo] = useState("Nenhum");

  // Estado para a rolagem de tela
  const [scrollSpeed, setScrollSpeed] = useState(0); // Estado para controlar a velocidade da rolagem
  const scrollRef = useRef(null); // Referência para o container de rolagem
  const scrollIntervalRef = useRef(null); // Referência para armazenar o intervalo de rolagem

   // Função para lidar com a rolagem
  const handleScrollButtonClick = () => {
    setScrollSpeed((prevSpeed) => {
      const newSpeed = (prevSpeed + 1) % 2; // Incrementa e volta para 0 após atingir a velocidade 5
      return newSpeed; // Mantém o valor como índice direto (0 a 5)
    });
  };

  // Atualiza a velocidade e reinicia a rolagem
  useEffect(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current); // Limpa qualquer intervalo existente
    }
  
    if (scrollSpeed > 0) {
      scrollIntervalRef.current = setInterval(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollBy({
            top: scrollSpeed * 1, // Controla a velocidade da rolagem
            behavior: 'smooth',
          });
        }
      }, 10); // Frequência da rolagem
    }
  
    return () => {
      clearInterval(scrollIntervalRef.current); // Limpa ao desmontar ou alterar a velocidade
    };
  }, [scrollSpeed]);

  const handleVerifyEmail = () => {
    navigate('/verify-email');
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        //console.log('Usuário autenticado:', user.uid);
        //console.log('E-mail verificado:', user.emailVerified);
        setUserId(user.uid);
        setIsAuthenticated(true);
        setIsEmailVerified(user.emailVerified);
        const userDocRef = doc(firestore, `users/${user.uid}`);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setRole(userData.role);
        }
      } else {
        setUserId(null);
        setLiked(false);
        setIsAuthenticated(false);
        setIsEmailVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  // Função para navegar até a tela de edição
  const handleEditClick = () => {
    navigate(`/edit-song/${id}`);
  };

  useEffect(() => {
    const loadSongData = async () => {
      try {
        const { songData, songContentData, songDetailsData, songContentDataId } =
          await fetchSongData(id);
        setSong(songData);
        setChords(songContentData.chords);
        const { chordsComponent, lyricsComponent, chordsList } = formatSong(
          songContentData.chords,
          handleChordClickInContent
        );
        setChordsComponent(chordsComponent);
        setLyricsComponent(lyricsComponent);
        setSongContent(chordsComponent);
        setChordsList(chordsList);
        setTone(songContentData.tone);
        setOriginalTone(songContentData.tone);
        setDetails(songDetailsData);

        const songRef = doc(firestore, `songs/${id}`);
        await updateDoc(songRef, {
          searches_count: increment(1),
        });

        const artistRef = doc(firestore, `artists/${songData.artist_id}`);
        await updateDoc(artistRef, {
          searches_count: increment(1),
        });

        const songContentRef = doc(firestore, `songs/${id}/songs_content/${songContentDataId}`);
        if (!songContentData.capo) {
          await updateDoc(songContentRef, { capo: "" });
          setCapo("Nenhum");
        } else {
          setCapo(songContentData.capo);
        }
      } catch (error) {
        //console.log(error.message);
      }
    };
    loadSongData();
  }, [id]);

  useEffect(() => {
    // Função auxiliar para encontrar o índice de um tom
    const findToneIndex = (tone) => {
      const majorTones = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'];
      return majorTones.indexOf(tone);
    };
  
    // Função para calcular a tonalidade efetiva com base no capotraste
    const calculateEffectiveTone = (originalTone, capoShift) => {
      const majorTones = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'];
      const originalToneIndex = findToneIndex(originalTone);
      if (originalToneIndex === -1) return originalTone; // Retorna o tom original se for inválido
  
      const newToneIndex = (originalToneIndex + capoShift) % majorTones.length;
      return majorTones[newToneIndex];
    };
  
    if (capo && capo !== "Nenhum") {
      const capoShift = parseInt(capo.split("º")[0], 10); // Converte a posição do capotraste para um número
      const effectiveTone = calculateEffectiveTone(originalTone, capoShift);
  
      // Atualiza a tonalidade exibida com o tom efetivo
      setTone(`${effectiveTone} (${originalTone})`);
  
      // Não transpor os acordes, mas manter os mesmos visuais
      const { chordsComponent, lyricsComponent, chordsList } = formatSong(chords, handleChordClickInContent);
  
      setChordsComponent(chordsComponent);
      setLyricsComponent(lyricsComponent);
      setChordsList(chordsList);
  
      if (selectedTab === 'Cifra') {
        setSongContent(chordsComponent);
      } else if (selectedTab === 'Letra') {
        setSongContent(lyricsComponent);
      }
    } else {
      // Sem capotraste, use o tom original e acorde original
      setTone(originalTone);
  
      const { chordsComponent, lyricsComponent, chordsList } = formatSong(chords, handleChordClickInContent);
  
      setChordsComponent(chordsComponent);
      setLyricsComponent(lyricsComponent);
      setChordsList(chordsList);
  
      if (selectedTab === 'Cifra') {
        setSongContent(chordsComponent);
      } else if (selectedTab === 'Letra') {
        setSongContent(lyricsComponent);
      }
    }
  }, [capo, chords, selectedTab, originalTone]);
  

  useEffect(() => {
    if (userId) {
      const updateRecentSongs = async () => {
        //console.log('Iniciando updateRecentSongs para userId:', userId);

        try {
          const recentSongRef = doc(
            firestore,
            `users/${userId}/recent_songs/${id}`
          );
          const docSnap = await getDoc(recentSongRef);

          //console.log('Documento existente:', docSnap.exists());

          if (docSnap.exists()) {
            await updateDoc(recentSongRef, {
              last_access_at: new Date(),
              hits: increment(1),
            });
            //console.log('Documento atualizado com sucesso.');
          } else {
            await setDoc(recentSongRef, {
              first_access_at: new Date(),
              last_access_at: new Date(),
              hits: 1,
            });
            //console.log('Documento criado com sucesso.');
          }

          const recentSongsRef = collection(
            firestore,
            `users/${userId}/recent_songs`
          );
          const q = query(
            recentSongsRef,
            orderBy('last_access_at', 'desc')
          );
          const querySnapshot = await getDocs(q);

          //console.log('Total de documentos em recent_songs:', querySnapshot.size);

          if (querySnapshot.size > 10) {
            const songsToDelete = querySnapshot.docs.slice(10);
            for (const docToDelete of songsToDelete) {
              await deleteDoc(docToDelete.ref);
              //console.log('Documento deletado:', docToDelete.id);
            }
          }
        } catch (error) {
          //console.error('Erro ao atualizar recent_songs:', error);
        }
      };

      updateRecentSongs();
    } else {
      //console.log('Usuário não autenticado. Não é possível atualizar recent_songs.');
    }
  }, [userId, id]);

  const handleToneChange = (newTone) => {
  
    const transposedChords = transposeChords(chords, originalTone, newTone, capo);
  
    setTone(newTone);
  
    const { chordsComponent, lyricsComponent, chordsList } = formatSong(transposedChords, handleChordClickInContent);
    setChordsComponent(chordsComponent);
    setLyricsComponent(lyricsComponent);
    setChordsList(chordsList);
  
    if (selectedTab === 'Cifra') {
      setSongContent(chordsComponent);
    } else if (selectedTab === 'Letra') {
      setSongContent(lyricsComponent);
    }
  };
  

  const handleTabClick = (tab) => {
    setSelectedTab(tab);

    if (tab === 'Cifra') {
      setSongContent(chordsComponent);
    } else if (tab === 'Letra') {
      setSongContent(lyricsComponent);
    } else if (tab === 'Detalhes' && details) {
      const detailsComponent = (
        <div style={{ color: '#fff' }}>
          <p>
            <span style={{ color: 'gray' }}>Compositor:</span>{' '}
            <span style={{ color: '#fff' }}>{details.composer || 'N/A'}</span>
          </p>
          <p>
            <span style={{ color: 'gray' }}>Produtor:</span>{' '}
            <span style={{ color: '#fff' }}>{details.producer || 'N/A'}</span>
          </p>
          <p>
            <span style={{ color: 'gray' }}>Outros:</span>{' '}
            <span style={{ color: '#fff' }}>{details.other || 'N/A'}</span>
          </p>
          <p>
            <span style={{ color: 'gray' }}>Lançamento:</span>{' '}
            <span style={{ color: '#fff' }}>{details.release || 'N/A'}</span>
          </p>
        </div>
      );
      setSongContent(detailsComponent);
    }
  };

  const handleLikeClick = async () => {
    if (!userId) {
      setOpenDialog(true);
      return;
    }

    if (!isEmailVerified) {
      // Usuário autenticado, mas sem e-mail verificado
      setOpenDialog(true);
      return;
    }
    
    const likedSongRef = doc(firestore, `users/${userId}/liked_songs/${id}`);
    const songRef = doc(firestore, `songs/${id}`);

    try {
      if (liked) {
        await deleteDoc(likedSongRef);
        await updateDoc(songRef, {
          likes_count: increment(-1),
        });
        setLiked(false);
      } else {
        await setDoc(likedSongRef, {
          created: new Date(),
        });
        await updateDoc(songRef, {
          likes_count: increment(1),
        });
        setLiked(true);
      }
    } catch (error) {
      //console.error('Erro ao atualizar o like:', error);
    }
  };

  const handleLogin = () => {
    navigate('/signin');
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenReportDialog = () => {
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const handleSendReport = () => {
    if (reportMessage.length >= 20) {
      sendEmail({
        message: reportMessage,
        to: 'worshipcifrasbr@gmail.com',
        cc: 'gustavo.honorio@yahoo.com.br',
        song_id: `${id}`,
        song_name: `${song.name}`,
        song_artist_id: `${song.artist_id}`,
        song_artist_name: `${song.artist_name}`,
      });
      setOpenReportDialog(false);
      setReportMessage('');
      alert(
        'Sua notificação foi enviada com sucesso. Obrigado por apoiar a comunidade para um WC ainda melhor.'
      );
    } else {
      alert('A mensagem precisa ter pelo menos 20 caracteres.');
    }
  };

  const handlePlayButtonClick = () => {
    if (!videoId) {
      fetchYouTubeVideo();
    }
    setShowPlayer(true);
  };

  const fetchYouTubeVideo = async () => {
    try {
      const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
      const query = `${song.name} ${song.artist_name}`;
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=1&type=video&q=${encodeURIComponent(
          query
        )}&key=${API_KEY}`
      );
      const data = await response.json();
      if (data.items && data.items.length > 0) {
        setVideoId(data.items[0].id.videoId);
      } else {
        console.error('Nenhum vídeo encontrado no YouTube.');
      }
    } catch (error) {
      console.error('Erro ao buscar vídeo do YouTube:', error);
    }
  };

  const handleChordClickInContent = (chord) => {
    setFocusChord(chord);
    setShowChords(true);
  };

  const handleShowChords = () => {
    setShowChords(true);
    setFocusChord(null);

    // Rola até a seção de acordes
    setTimeout(() => {
      chordSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const handleCloseChords = () => {
    setShowChords(false);
    setFocusChord(null);
  };

  const chordsContainerRef = useRef(null);

  useEffect(() => {
    if (focusChord && chordsContainerRef.current) {
      const chordElement = chordsContainerRef.current.querySelector(`[data-chord="${focusChord}"]`);
      if (chordElement) {
        chordElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }
  }, [focusChord]);

  if (!song) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh', // Ajuste conforme necessário
        }}
      >
        <CircularProgress />
        <Typography sx={{ marginTop: 2 }}>Preparando sua canção</Typography>
      </Box>
    );
  }

  const playerHeight = isMobile ? 250 : 400;

  return (
    <Box ref={scrollRef} sx={{ padding: 2, position: 'relative', height: '100vh', overflowY: 'auto', paddingBottom: showPlayer ? `${playerHeight}px` : '0px' }}>
      <NavigationBar />

      {/* Renderizar o player condicionalmente no topo */}
      {showPlayer && videoId && (
        <YouTubePlayer
          videoId={videoId}
          onClose={() => setShowPlayer(false)}
        />
      )}

      <SongInfo
        song={song}
        liked={liked}
        handleLikeClick={handleLikeClick}
      />
      <TabSelector
        selectedTab={selectedTab}
        handleTabClick={handleTabClick}
      />
      <SongActions
        selectedTab={selectedTab}
        tone={tone}
        capo={capo}
        setCapo={setCapo}
        handleToneChange={handleToneChange}
        handleOpenReportDialog={handleOpenReportDialog}
        songName={song.name}
        artistName={song.artist_name}
      />
      <SongContent songContent={songContent} />

      <AuthDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleLogin={handleLogin}
        handleVerifyEmail={handleVerifyEmail} // Função para redirecionar para verificação de e-mail
        isAuthenticated={isAuthenticated} // Passar o estado de autenticação
        isEmailVerified={isEmailVerified} // Passar o estado de verificação de e-mail
      />
      <ReportDialog
        openReportDialog={openReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
        reportMessage={reportMessage}
        setReportMessage={setReportMessage}
        handleSendReport={handleSendReport}
      />

      {/* Botão "Editar" visível apenas para admins */}
      {role === 'admin' && (
        <Box
        sx={{
          position: 'fixed',
          bottom: 200,
          right: 20,
          backgroundColor: '#A52422',
          borderRadius: '30px',
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }}
        onClick={handleEditClick}
        >
          <CreateIcon sx={{ color: '#fff', fontSize: 30 }} />
          <Typography sx={{ color: '#fff', marginLeft: 1 }}>Editar</Typography>
        </Box>
      )}

      {/* Botão "Rolar" */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 140,
          right: 20,
          backgroundColor: '#A52422',
          borderRadius: '30px',
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          zIndex: 2,
        }}
        onClick={handleScrollButtonClick}
      >
        <ExpandMoreIcon sx={{ color: '#fff', fontSize: 30 }} />
        <Typography sx={{ color: '#fff', marginLeft: 1 }}>
          {scrollSpeed === 0 ? 'Rolar' : `${scrollSpeed}x`}
        </Typography>
      </Box>

      {/* Botão "Ouvir agora" */}
      {!showPlayer && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            backgroundColor: '#A52422',
            borderRadius: '30px',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          }}
          onClick={handlePlayButtonClick}
        >
          <PlayArrowIcon sx={{ color: '#fff', fontSize: 30 }} />
          <Typography sx={{ color: '#fff', marginLeft: 1 }}>Ouvir agora</Typography>
        </Box>
      )}

      {/* Botão "Acordes" */}
      {!showChords && (
      <Box
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 20,
          backgroundColor: '#A52422',
          borderRadius: '30px',
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          zIndex: 2,
        }}
        onClick={handleShowChords}
      >
        <QueueMusicIcon sx={{ color: '#fff', fontSize: 30 }} />
        <Typography sx={{ color: '#fff', marginLeft: 1  }}>Acordes</Typography>
      </Box>
      )}

      {/* Seção de Acordes */}
      {showChords && (
        <Box
          ref={chordSectionRef}
          sx={{
            backgroundColor: '#222',
            padding: 2,
            borderRadius: 2,
            marginTop: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              variant="h6"
              onClick={handleCloseChords}
              sx={{ cursor: 'pointer', color: '#fff' }}
            >
              X
            </Typography>
          </Box>
          <Box
            ref={chordsContainerRef}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: 1,
            }}
          >
            {chordsList.map((chord) => (
              <Box key={chord} data-chord={chord} sx={{ margin: 2 }}>
                <ChordDiagram chordName={chord} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Song;
