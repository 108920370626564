// PrivacyPolicy.js
import React from 'react';
import { Box, Typography, Container, Divider } from '@mui/material';
import NavigationBar from '../common/NavigationBar';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#1e1e1e',
        color: '#fff',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <NavigationBar />

      <Container maxWidth="sm" sx={{ marginBottom: '5rem' }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 'bold', marginBottom: 3, textAlign: 'center' }}
        >
          Política de Privacidade
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          sx={{ marginBottom: 3, fontStyle: 'italic', textAlign: 'center' }}
        >
          Última atualização: 16/10/2024
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 1 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          1. Coleta de Informações
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong>Informações Pessoais:</strong> Podemos coletar informações que
          identificam você pessoalmente, como nome, endereço de e-mail, quando você se
          registra no site ou entra em contato conosco.
          <br />
          <strong>Informações Não Pessoais:</strong> Podemos coletar informações não
          pessoais, como dados demográficos, endereço IP, tipo de navegador, páginas
          visualizadas e tempo gasto no site.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 2 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          2. Uso de Informações
        </Typography>
        <Typography variant="body2" gutterBottom>
          Utilizamos as informações coletadas para:
          <ul>
            <li>Fornecer e manter nosso serviço.</li>
            <li>Personalizar e melhorar sua experiência no site.</li>
            <li>
              Enviar comunicações, como atualizações, notificações e suporte ao cliente.
            </li>
            <li>Monitorar e analisar o uso do site para melhorias.</li>
          </ul>
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 3 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          3. Compartilhamento de Informações
        </Typography>
        <Typography variant="body2" gutterBottom>
          Não vendemos, alugamos ou compartilhamos suas informações pessoais com
          terceiros, exceto:
          <ul>
            <li>
              <strong>Provedores de Serviços:</strong> Podemos compartilhar informações
              com fornecedores terceirizados que nos auxiliam na operação do site.
            </li>
            <li>
              <strong>Exigências Legais:</strong> Podemos divulgar informações se
              exigido por lei ou em resposta a solicitações legais.
            </li>
          </ul>
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 4 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          4. Cookies e Tecnologias Semelhantes
        </Typography>
        <Typography variant="body2" gutterBottom>
          Utilizamos cookies para melhorar sua experiência no site. Você pode optar por
          desativar os cookies nas configurações do seu navegador, mas isso pode afetar
          a funcionalidade do site.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 5 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          5. Segurança de Dados
        </Typography>
        <Typography variant="body2" gutterBottom>
          Implementamos medidas de segurança apropriadas para proteger suas informações
          pessoais contra acesso não autorizado, alteração ou destruição.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 6 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          6. Direitos dos Usuários
        </Typography>
        <Typography variant="body2" gutterBottom>
          De acordo com a LGPD, você tem o direito de:
          <ul>
            <li>Acessar suas informações pessoais.</li>
            <li>Solicitar correção de dados incompletos ou desatualizados.</li>
            <li>
              Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários.
            </li>
            <li>Revogar o consentimento para o processamento de dados.</li>
          </ul>
          Para exercer esses direitos, entre em contato conosco através do e-mail abaixo.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 7 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          7. Links para Sites de Terceiros
        </Typography>
        <Typography variant="body2" gutterBottom>
          Nosso site pode conter links para outros sites que não são operados por nós.
          Não nos responsabilizamos pelo conteúdo ou práticas de privacidade desses
          sites.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 8 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          8. Alterações nesta Política de Privacidade
        </Typography>
        <Typography variant="body2" gutterBottom>
          Podemos atualizar nossa Política de Privacidade periodicamente. Notificaremos
          sobre quaisquer alterações publicando a nova política nesta página.
          Recomenda-se revisar esta política regularmente.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 9 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          9. Contato
        </Typography>
        <Typography variant="body2" gutterBottom>
          Se tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em
          contato conosco:
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          sx={{ textAlign: 'center', fontWeight: 'bold', color: '#A52422', marginTop: 5 }}
        >
          Worship Cifras
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
